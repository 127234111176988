import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
export const _frontmatter = {
  "product": "signatures",
  "category": "Integrations",
  "sort": 1,
  "title": "Node.js",
  "subtitle": "Integrate Criipto Signatures with Node.js to sign PDFs using MitID, Swedish BankID, Norwegian BankId or other eIDs."
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This guide shows you how to use implement Criipto Signatures in Node.js to sign
PAdeS-LTA documents using MitID, BankID or any other eID supported by Criipto.`}</p>
    <h2>{`Getting started`}</h2>
    <h3>{`Requirements`}</h3>
    <p>{`This library supports Node 16 and later.`}</p>
    <h3>{`Installation`}</h3>
    <p>{`The SDK is available on `}<a parentName="p" {...{
        "href": "https://npmjs.com/package/@criipto/signatures"
      }}>{`NPM`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{}}>{`npm install --save @criipto/signatures
yarn add @criipto/signatures
`}</code></pre>
    <h3>{`Configure the SDK`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import CriiptoSignatures from '@criipto/signatures';
const client = new CriiptoSignatures("{YOUR_CRIIPTO_CLIENT_ID}", "{YOUR_CRIIPTO_CLIENT_SECRET}");
`}</code></pre>
    <h2>{`Creating your first signature order`}</h2>
    <p>{`Create a signature order by `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import CriiptoSignatures from '@criipto/signatures';
const client = new CriiptoSignatures("{YOUR_CRIIPTO_CLIENT_ID}", "{YOUR_CRIIPTO_CLIENT_SECRET}");

// Create signature order
const signatureOrder = await client.createSignatureOrder({
  title: "Node.js sample",
  documents: [
    {
      pdf: {
        title: "Node.js Sample",
        blob: pdf.toString('base64'),
        storageMode: 'Temporary'
      }
    }
  ]
});

// ... store the signatureOrder.id
`}</code></pre>
    <h3>{`Adding signatories`}</h3>
    <p>{`Signatories are the individuals you wish to sign your documents.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import CriiptoSignatures from '@criipto/signatures';
const signatureOrderId = "..." // The id from when you previosuly created the signature order

// Add signatory to signature order
var signatory = await client.addSignatory(signatureOrderId);

console.log(signatory.href); // The signatory.href is the signing link that you can show or send to your users
`}</code></pre>
    <h3>{`Closing the signature order`}</h3>
    <p>{`Once everyone has signed you must close the signature order to finalize the process and extract the signed documents.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const client = new CriiptoSignatures("{YOUR_CRIIPTO_CLIENT_ID}", "{YOUR_CRIIPTO_CLIENT_SECRET}");
var signatureOrderId = "..." // The id from when you previosuly created the signature order

// Close signature order
var closedSignatureOrder = await client.closeSignatureOrder(signatureOrderId);

closedSignatureOrder.documents.forEach(document => {
  // document.blob is a Buffer containing the signed PDF
});
`}</code></pre>
    <h2>{`More examples`}</h2>
    <p>{`More `}<a parentName="p" {...{
        "href": "/signatures/graphql/examples/"
      }}>{`Node.js examples`}</a>{` are provided for all the options supported by the Criipto Signatures API.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      